/**
* Created by hansxing on 2018/8/7.
*/
<style lang="scss" scoped>
    .operator-list {

        .screen-radio-change {
            margin-bottom: 10px;
            ul {
                li {
                    float: left;
                    margin-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        .operator-list-table {
            background: #fff;
            margin-top: 10px;
            /*padding: 0 20px;*/
            .not-through-popover {
                display: inline-block;
                cursor: pointer;
                .el-popover__reference {
                    color: #FF3B30;
                }
            }
        }
    }
</style>
<style lang="scss">
    .operator-list {
        .common-list-search {
            .list-search-before {
                width: calc(100% - 156px);
            }
            .list-search-after {
                width: 156px;
                text-align: right;
            }
        }
    }
</style>
<template>

    <div>
        <el-main class="com-eTrip-section">
            <div class="eTrip-section-cont operator-list">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form :inline="true" size="small">
                            <el-form-item label="">
                                <el-input v-model="operatorInfoInput.corporateName" placeholder="请输入公司名称"></el-input>
                            </el-form-item>
                            <el-form-item label="">
                                <el-input v-model="operatorInfoInput.operatorId" placeholder="运营商ID"></el-input>
                            </el-form-item>
                            <!--<el-form-item label="">-->
                            <!--<el-input v-model="operatorInfoInput.operateCity" placeholder="运营城市"></el-input>-->
                            <!--</el-form-item>-->
                            <el-form-item label="" style="width: 166px">
                                <el-radio-group v-model="operatorTypeState.screenContract">
                                    <el-radio-button :label="-1">全部</el-radio-button>
                                    <el-radio-button :label="0">有效
                                        <!--<span v-text="operatorCompanyTable.contractWillExpireCount"></span>-->
                                    </el-radio-button>
                                    <el-radio-button :label="1">无效
                                        <!--<span v-text="operatorCompanyTable.contractExpiredCount"></span>-->
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div slot="list-search-after" class="list-search-after_sec">
                        <el-button @click="operatorScrResetClick()">重置</el-button>
                        <el-button type="primary" @click="operatorScrBtnClick()">搜索</el-button>
                    </div>
                </com-list-search>

                <div class="operator-list-table el-table_border_none">
                    <el-table class="table-info"
                              border
                              :data="operatorCompanyTable"
                              v-loading="operatorTableLoading"
                              style="width: 100%">
                        <el-table-column
                                fixed
                                prop="operatorId"
                                label="运营商ID"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop="operatorName"
                                label="公司名称"
                                min-width="150px">
                        </el-table-column>
                        <!--<el-table-column-->
                        <!--label="运营城市"-->
                        <!--min-width="200">-->
                        <!--<template slot-scope="scope">-->
                        <!--<span >二级用户</span>-->
                        <!--</template>-->
                        <!--</el-table-column>-->
                        <el-table-column
                                label="有效性"
                                width="220">
                            <template slot-scope="scope">
                                <p v-if="scope.row.isDeleted == 0">有效</p>
                                <p v-if="scope.row.isDeleted == 1">无效</p>
                            </template>
                        </el-table-column>
                        <!--<el-table-column-->
                        <!--label="账号数量"-->
                        <!--width="80">-->
                        <!--<template slot-scope="scope">-->
                        <!--<p>5</p>-->
                        <!--</template>-->
                        <!--</el-table-column>-->

                        <!--<el-table-column-->
                        <!--fixed="right"-->
                        <!--label="操作"-->
                        <!--width="100">-->
                        <!--<template slot-scope="scope">-->
                        <!--<el-button @click="seeListInfoClick(scope.row.id)" type="text" size="small">查看</el-button>-->
                        <!--</template>-->
                        <!--</el-table-column>-->
                    </el-table>
                    <div class="table-page-number">
                        <div class="block">
                            <el-pagination
                                    @current-change="operatorTablePageNumChange"
                                    :current-page.sync="operatorListPage.currentPage"
                                    :page-size="operatorListPage.pageSize"
                                    layout="total, prev, pager, next"
                                    :total="operatorListPage.totalPage">
                            </el-pagination>
                        </div>
                    </div>
                </div>

            </div>
        </el-main>

    </div>

</template>

<script type="text/ecmascript-6">
    import comListSearch from '../../../components/comListSearch/index.vue'

    export default {
        name: "operatorList",
        //定义模版数据
        data() {
            return {
                operatorTypeState: {  // 审核状态、用户类型、合同
                    screenContract: -1,  // 合同是否过期  0全部，1合同将过期，2合同已过期
                },
                operatorInfoInput: {  // 输入
                    operatorId: '',  // 运营商ID
                    corporateName: '',  // 公司名称
                    operateCity: '',  // 运营城市
                },
                operatorCompanyTable: [
//                    {orgId: '3456789',  // 运营商ID
//                    companyName: '上海久柏目的地旅游服务有限公司',  // 公司名称
//                    businessContractName: '杨宗纬',  // 业务联系人姓名 ,
//                    businessContractPhone: '13487221888',  // 业务联系人手机号
//                    firstMerchantValidate:'0',  // 一级用户  0无效 1有效
//                    secondMerchantValidate:'2',  // 二级用户  0无效 1有效
//                    supplierValidate:'0',  // 供应商  0无效 1有效
//                    operatorValidate:'1',  // 运营商  0无效 1有效
//                    approvalStatus:'1',  // 审核状态 1待提交，2待审核，3已通过，4未通过 ,
//                }
                ],
                operatorTableLoading: false,
                operatorListPage: {  // 列表分页
                    pageSize: 10,  // 每页显示条目个数
                    totalPage: 1,  // 总条目个数
                    currentPage: 1,  // 当前页数
                },
            }
        },
        components: {comListSearch},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
            this.operatorOrgList()

        },
        //定义事件方法
        methods: {

            async operatorOrgList() {  // 获取运营商列表
                this.operatorTableLoading = true;
                let form = {
                    pageIndex: this.operatorListPage.currentPage,
                    pageSize: this.operatorListPage.pageSize
                };
                if (this.operatorInfoInput.corporateName) form.companyName = this.operatorInfoInput.corporateName;  // 公司名称
                if (this.operatorInfoInput.operatorId) form.operatorId = this.operatorInfoInput.operatorId;  // 运营商ID
//                if (this.operatorInfoInput.operateCity) form.operateCity = this.operatorInfoInput.operateCity;  // 运营城市
                form.isDeleted = this.operatorTypeState.screenContract == -1 ? '' : this.operatorTypeState.screenContract;  // 合同是否过期

                try {
                    let res = await this.http('/galaxyOperator/operatorInfoPageList', form, 'POST');
                    if (res.success) {
                        this.operatorTableLoading = false;
                        this.operatorCompanyTable = res.data.data;
                        this.operatorListPage.totalPage = res.data.total;  // 总条目个数
                    }
//                    else {
                    // this.messageError(res.errors[0].message);
//                        this.messageError('系统出错，请稍后!');
//                    }
                } catch (error) {
                    this.messageError()
                }
            },
            operatorScrBtnClick() {  // 搜索
                this.operatorListPage.currentPage = 1;
                this.operatorOrgList()


            },
            operatorScrResetClick() {  // 重置
                this.operatorInfoInput.corporateName = '';  // 公司名称
                this.operatorInfoInput.operatorId = '';  // 运营商ID
                this.operatorInfoInput.operateCity = '';  // 运营城市
                this.operatorTypeState.screenContract = 0;  // 合同是否过期
                this.operatorOrgList()
            },
            operatorTablePageNumChange(val) {
                this.operatorListPage.currentPage = val;
                this.operatorOrgList()
            },
        },
        //监听模版变量
        watch: {}

    }
</script>