/**
* Created by hansxing on 2018/12/18.
*/
<style lang="scss">
    // 订单列表头 搜索
    .common-list-search{
        background: #fff;
        padding: 10px 20px;
        display: flex;
        margin: 10px 0;

        .list-search-before{
            width: calc(100% - 150px);
            .el-form--inline .el-form-item{
                margin-right: 5px;
            }
            .el-form-item.el-form-item--small {
                width: 125px;
                margin-bottom: 18px;
                &.el-label_w{
                    width: auto;
                }
            }
            .el-form-item__content{
                width: 100%;
            }
            .el-label_w{
                .el-form-item__content{
                    width: auto;
                }
            }

        }

        .list-search-after {
            position: relative;
            width: 150px;
            /*background: #fff;*/
            .el-button {
                padding: 8px 20px;
            }
            .search-after-switch{
                background: transparent;
                border: 0;
                padding: 5px;
                color: #409EFF;
            }
        }
        .list-search-after_sec{
            position: absolute;
            bottom: 10px;
            right: 0;
        }
    }
</style>
<template>

    <div class="common-list-search">
        <div class="list-search-before">
            <slot name="list-search-before"></slot>
        </div>
        <div class="list-search-after">
            <slot name="list-search-after"></slot>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            let data
            return {
                data
            }
        },
    }
</script>